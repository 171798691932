import React from 'react'
import { Link } from 'gatsby'
import { removeFormValues } from "../services/form"
import { sessionKeys } from "../services/const"
import Seo from '../components/seo'

class CnahgeInfoComplete extends React.Component {
  componentDidMount = () => {
    removeFormValues(sessionKeys.changeForm);
  }
  render = () => {
    return (
      <>
        <Seo title="登録内容の変更" />
        <div id="pageTitle">
          <h1>
            登録内容の変更
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/member">入会のご案内</Link></li>
            <li>登録内容の変更</li>
          </ul>
        </div>
        <div id="main">
          <div className="contents">
            <section className="contents_block">
              <h2 className="header01">送信が完了しました</h2>
              <div className="inner">
                <p className="mb100">登録内容の変更を受付いたしました。<br />
                  後日、送信いただいた内容について協会からご連絡させていただく場合がございます。</p>
              </div>
            </section>
          </div>
        </div>
      </>
    )
  }
}

export default CnahgeInfoComplete